:root {
  --color-bg: #fff;
  --color-text: #000;
}

[data-theme="dark"] {
  --color-bg: #000;
  --color-text: #fff;
}

body {
  background-color: var(--color-bg);
  color: var(--color-text);
}

body.light {
  background-color: #ffffff;
  color: #000000;
}

body.dark {
  background-color: #323339;
  color: #ffffff;
}

.navbar-light {
  background-color: #f8f9fa;
}

.navbar-dark {
  background-color: #343a40;
}

.content.expanded {
  margin-left: 0;
}

.position-fixed {
  z-index: 1000; /* Ensure it is above other content */
}

html,
body {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

#external-events .fc-event {
  cursor: pointer;
  margin: 10px 0;
}
#external-events {
  /* padding: 1px; */
  /* background: #f7f7f7; */
  /* border-right: 1px solid #ddd; */
  text-align: left;
}

.fc-event {
  margin: 1px 0;
  cursor: pointer;
}
.fc-timeline-event-harness {
  padding: 0px; /* Adjust as needed */
  margin: 0px;  /* Adjust as needed */
}
.unscheduled-event {
  background-color: red;
  width: 100%;
  text-align: center;
  padding: 2px;
}

.unscheduled-event::before {
  content: attr(title);
  display: block;
  font-size: 12px;
  color: white;
  text-align: left;
}
.fc-customToday-button.fc-button.fc-button-primary {
  border: 1px solid rgb(253, 3, 3) !important;
}
.fc .fc-customToday-button.fc-button.fc-button-primary {
  border-color: lightgray!important;
  background-color: #fff !important;
  color: black;

}
.fc-customPrev-button {
  visibility: hidden;
  position: relative;
  width: 30px;
}

.fc-customPrev-button::before {
  content: "\f104"; /* FontAwesome left arrow */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  visibility: visible;
  position: absolute;
  left: 0;
  right: 0;
  color: #000;
}

.fc-customNext-button {
  visibility: hidden;
  position: relative;
  width: 30px;
}

.fc-customNext-button::before {
  content: "\f105"; /* FontAwesome right arrow */
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  visibility: visible;
  position: absolute;
  left: -20px;
  right: 0;
  color: #000;
}
.fc-customPrev-button:hover::before,
.fc-customNext-button:hover::before {
  color: red;
}
.fc-customToday-button.fc-button.fc-button-primary:hover {
  color: red;
}
.fc-event-main {
  border-radius: 0 !important;
}
.fc .fc-toolbar.fc-header-toolbar {
   margin-bottom: 0em!important;
  background-color: darkgrey;
}
.tooltip {
  z-index: 1000;

}
.tooltip {
  position: absolute;
  background-color: black;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 100;
  display: none; /* Initially hidden */
}

.fc-timeline-slot-cushion.fc-scrollgrid-sync-inner {
  color: purple; /* Replace with your desired color */
  text-decoration: none; /* Removes underline */
}
.fc-timeline-slot-frame {
  border-bottom: black 1px solid;
  color: red; /* Replace with your desired color */
  text-decoration: none; /* Removes underline */
  background-color: lightgray;
}
.fc-datagrid-cell-frame {
background-color: lightgrey;
  color: black;
}
.react-datepicker__input-container input {
  border: 1px solid #ced4da; /* Add a border */
  padding: 0.375rem 0.75rem; /* Add padding for better appearance */
  border-radius: 0.25rem; /* Add border radius for rounded corners */
  box-shadow: none; /* Remove any default shadow */
  outline: none; /* Remove default outline */
}

.react-datepicker__input-container input:focus {
  border-color: #80bdff; /* Change border color on focus */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Add shadow on focus */
}
.btn-custom:focus {
  outline: none;
  box-shadow: none;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.fc-timeline-slot.fc-timeline-slot-label.fc-day.fc-day-thu.fc-day-today
{
  background-color: #ffeb3b; /* background color for current day header */
}
/* Style the <em> tags to highlight text */
.dropdown-item em {
  background-color: #D7E7FF ; /* Or any color you prefer */
  font-style: normal; /* Remove italic style if desired */
  padding: 0 2px; /* Optional: add some padding */
}
.dropdown-item:last-child {
  border-bottom: none !important;
}
.max-vh-70 {
  max-height: 70vh;
}
.width-500px{
  width: 500px;
}
